import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import bitcoinPic from "../../Assets/bitcoin.svg";
import yahtzeePic from "../../Assets/yahtzee.svg";
import photogalleryPic from "../../Assets/photogallery.svg";
import GitHubIcon from "@mui/icons-material/GitHub";

const Portfolio = React.forwardRef((props, ref) => {
	return (
		<>
			<Typography
				id="portfolio"
				gutterBottom
				variant="h4"
				sx={{ textAlign: "center", marginTop: "100px" }}
			>
				Portfolio:
			</Typography>
			<div id="portfolio" ref={ref}></div>
			<Typography
				gutterBottom
				variant="h5"
				sx={{ textAlign: "center", marginTop: "10px" }}
			>
				Take a look at the code at my GitHub:
				<a href="https://github.com/virtanen-ville">
					<GitHubIcon
						sx={{
							color: "primary.main",
							//textAlign: "center",
							fontSize: 40,
						}}
					/>
				</a>
			</Typography>
			{/* <Box
				sx={{
					textAlign: "center",
				}}
			>
				<a href="https://github.com/virtanen-ville">
					<GitHubIcon
						sx={{
							color: "primary.main",
							textAlign: "center",
							fontSize: 80,
						}}
					/>
				</a>
			</Box> */}
			<Typography
				gutterBottom
				variant="h5"
				sx={{ textAlign: "center", marginTop: "20px" }}
			>
				Or check out some of the apps I have made:
			</Typography>
			<Box
				sx={{
					marginBottom: "100px",
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					"& > :not(style)": {
						m: 1,
						width: "280px",
						height: "440px",
					},
				}}
			>
				<Card>
					<CardMedia
						component="img"
						image={bitcoinPic}
						alt="bitcoin"
					/>
					<CardContent>
						<Typography gutterBottom variant="h7" component="div">
							Bitcoin Timemachine
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Check bitcoin value
						</Typography>
					</CardContent>
					<CardActions>
						<Button
							size="small"
							variant="contained"
							href="https://bitcoin-tracking.herokuapp.com"
						>
							Check it out
						</Button>
					</CardActions>
				</Card>

				<Card>
					<CardMedia
						component="img"
						image={yahtzeePic}
						alt="yahtzee"
					/>
					<CardContent>
						<Typography gutterBottom variant="h7" component="div">
							Multiplayer Yahtzee
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Play Yahtzee online
						</Typography>
					</CardContent>
					<CardActions>
						<Button
							size="small"
							variant="contained"
							href="https://yahtzee.herokuapp.com"
						>
							Check it out
						</Button>
					</CardActions>
				</Card>
				<Card>
					<CardMedia
						component="img"
						image={photogalleryPic}
						alt="photogallery"
					/>
					<CardContent>
						<Typography gutterBottom variant="h7" component="div">
							Photogallery
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Instagram style photos app
						</Typography>
					</CardContent>
					<CardActions>
						<Button
							size="small"
							variant="contained"
							href="https://photogallery.herokuapp.com"
						>
							Check it out
						</Button>
					</CardActions>
				</Card>
			</Box>
		</>
	);
});
export default Portfolio;
